var royalslider = {

    init: function() {

        if($('#fullWidthSlider').length > 0) {

            $('#fullWidthSlider').royalSlider({

                //autoScaleSliderWidth: 1920,
                //autoScaleSliderHeight: 1080,
                addActiveClass: true,
                slidesSpacing: 10,
                arrowsNav: true,
                loop: true,
                keyboardNavEnabled: true,
                controlNavigationSpacing: 20,
                controlsInside: false,
                imageScaleMode: 'fit',
                imageScalePadding: 0,
                imageAlignCenter: true,
                arrowsNavAutoHide: false,
                autoScaleSlider: false,
                controlNavigation: 'bullets',
                navigateByClick: true,
                startSlideId: 0,
                transitionType:'move',
                globalCaption: false,
                numImagesToPreload: 4,
                deeplinking: {
                    enabled: true,
                    change: false
                },
                autoPlay: {
                    enabled: false,
                    pauseOnHover: true,
                    delay: 4000
                },
                visibleNearby: {
                    enabled: true,
                    centerArea: .5,
                    center: true,
                    breakpointCenterArea: 0.64,
                    breakpoint: 650,
                }
            });
        }

        if($('#homeSlider').length > 0) {

            $('#homeSlider').royalSlider({

                autoScaleSliderWidth: 1920,
                autoScaleSliderHeight: 1080,
                slidesSpacing: 0,
                arrowsNav: false,
                loop: false,
                keyboardNavEnabled: true,
                controlsInside: false,
                imageScaleMode: 'fill',
                imageScalePadding: 0,
                imageAlignCenter: 'true',
                arrowsNavAutoHide: false,
                autoScaleSlider: false,
                controlNavigation: 'thumbnails',
                navigateByClick: true,
                startSlideId: 0,
                transitionType:'move',
                numImagesToPreload: 2,
                globalCaption: true,
                globalCaptionInside: true,
                thumbs: {
                    appendSpan: true,
                    firstMargin: false,
                    paddingBottom: 0,
                    spacing: 10,
                    fitInViewport: false
                },
                deeplinking: {
                    enabled: true,
                    change: false
                },
                autoPlay: {
                    enabled: false,
                    pauseOnHover: true,
                    delay: 4000
                }
            });
        }

        if($('#productSlider').length > 0) {

            $('#productSlider').royalSlider({
                fullscreen: {
                    enabled: true,
                    nativeFS: true
                },
                controlNavigation: 'thumbnails',
                autoScaleSlider: true,
                autoScaleSliderWidth: 960,
                autoScaleSliderHeight: 850,
                imageScalePadding: 0,
                loop: false,
                slidesSpacing: 0,
                controlsInside: false,
                imageScaleMode: 'fill',
                navigateByClick: true,
                numImagesToPreload:2,
                arrowsNav:true,
                arrowsNavAutoHide: true,
                arrowsNavHideOnTouch: true,
                keyboardNavEnabled: true,
                fadeinLoadedSlide: true,
                globalCaption: false,
                globalCaptionInside: false,
                thumbs: {
                    appendSpan: true,
                    firstMargin: true,
                    paddingBottom: 4
                }
            });
        }

        if($('#content-slider').length > 0) {

            $('#content-slider').royalSlider({
                fullscreen: {
                    enabled: false,
                    nativeFS: true
                },
                controlsInside: false,
                controlNavigation: 'bullets',
                autoScaleSlider: true,
                autoScaleSliderWidth: 960,
                //autoScaleSliderHeight: 600,
                loop: false,
                imageScaleMode: 'fit',
                navigateByClick: false,
                numImagesToPreload: 3,
                imageScalePadding: 40,
                arrowsNav: true,
                arrowsNavAutoHide: false,
                arrowsNavHideOnTouch: true,
                keyboardNavEnabled: true,
                fadeinLoadedSlide: true,
                globalCaption: true,
                globalCaptionInside: true
            }).data('royalSlider');
        }
    }
};