document.addEventListener('DOMContentLoaded', function () {

    if($('#map').length > 0) {

        map.init();

    } else if($('#mapdetail').length > 0) {
        mapdetail.init();
    } else {

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            data: {
                isActive: false
            },
            methods: {

                myFilter: function(){
                    console.log();
                    this.isActive = !this.isActive;
                    // some code to filter users
                }
            }
        });
    }

    royalslider.init();

    $("body").on("contextmenu",function(e){
        //return false;
    });

    if($('#FormBuilder_offerte').length > 0) {

        var tabbedForm = new steps('tabbedForm');
        tabbedForm.init();
    }

});
