Vue.component('tabs', {

    template: `
        <div>
            <div class="c-nav-tabs">
              <ul class="o-flex o-flex-align-content-start">
                <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }">
                    <a :href="tab.href" @click="selectTab(tab.name)" class="u-padding-sm">{{ tab.name }}</a>
                </li>
              </ul>
            </div>

            <div class="c-tabs-details">
                <slot></slot>
            </div>
        </div>
    `,

    data() {

        return {tabs: [] };
    },

    created() {

        this.tabs = this.$children;
    },

    methods: {

        selectTab(selectedTab) {

            this.tabs.forEach(tab => {
                tab.isActive = (tab.name == selectedTab);
            });
        }
    }

});

Vue.component('tab', {

    template: `<div v-show="isActive"><slot></slot></div>`,

    props: {
        name: { required: true },
        selected: { default: false}
    },

    data() {

        return {
            isActive: false
        };
    },

    computed: {

        href() {
            return '#' + this.name.toLowerCase().replace(/ /g, '-');
        }
    },

    mounted() {

        this.isActive = this.selected;
    }

});