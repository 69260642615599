Vue.component('frminput', {
  template: `
    <input class="c-form-input c-form-input-text" :type="type" :name="name" :value="value" :min="min" :max="max" :placeholder="placeholder">
  `,
  props: {
    type: {
      default: 'text',
      type: String
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    min: {
      type: String
    },
    max: {
      type: String
    }
  }
});

Vue.component('frmdatepicker', {
  template: `
    <div class="c-form-input-datepicker-container">
      <input type="text" class="c-form-input c-form-input-datepicker" readonly="readonly" :name="name" :value="value" :placeholder="placeholder">
    </div>
  `,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  mounted(){
    $(this.$el.querySelector('input')).datepicker();
  }
});

Vue.component('frmtextarea', {
  template: `
    <textarea class="c-form-input c-form-input-textarea" :name="name" :value="value" :placeholder="placeholder"></textarea>
  `,
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  }
});

Vue.component('frmradio', {
  template: `
    <div classc-form-input-radio-set>
      <div class="c-form-input-radio-container" v-for="(option, i) in options">
        <input type="radio" class="c-form-input c-form-input-radio" :name="name" :value="i" :id="'radio-' + i">
        <label :for="'radio-' + i">
          <i class="c-form-icon-radio"></i>
          {{option}}
        </label>
      </div>
    </div>
  `,
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  }
});

Vue.component('frmcheckbox', {
  template: `
    <div classc-form-input-checkbox-set>
      <div class="c-form-input-checkbox-container" v-for="option in options">
        <input type="checkbox" class="c-form-input c-form-input-checkbox" :name="name" :value="option.value" :id="'checkbox-' + name + '-' + option.value">
        <label :for="'checkbox-' + name + '-' + option.value">
          <i class="c-form-icon-checkbox"></i>
          <span v-html="option.label"></span>
        </label>
      </div>
    </div>
  `,
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  }
});

Vue.component('frmselect', {
  template: `
    <div class="c-form-input-select-container">
      <select class="c-form-input c-form-input-select" :name="name">
        <option disabled>{{placeholder}}</option>
        <option v-for="option in options" :value="option.value" :selected="option.selected">{{option.label}}</option>
      </select>
    </div>
  `,
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String
    }
  }
});

Vue.component('frmbutton', {
  template: `
    <button class="c-button" :type="type" :class="[(state ? 'c-button-' + state : '')]">{{label}}</button>
  `,
  props: {
    state: {
      type: String
    },
    type: {
      default: 'submit',
      type: String
    },
    label: {
      type: String,
      required: true
    }
  }
});