var mapdetail = {

    init: function () {

        Vue.use( VueGoogleMaps, {
            load: {
                key: 'AIzaSyDfFjkvWYIkOG43p2_88RQCbPtpMzlYNJ4',
                language: lang,
                region: region,
                libraries: 'places'
            }
        } );

        Vue.component( 'google-map', VueGoogleMaps.Map );
        Vue.component( 'google-marker', VueGoogleMaps.Marker );

        new Vue( {
            el: '#app',
            delimiters: [ '<%', '%>' ],
            data: {
                center: { lat: parseFloat(distributor_lat.replace(',','.')), lng: parseFloat(distributor_lng.replace(',','.')) },
                zoom: 12,
                scrollwheel: true,
                markers: distributors,
            },
           
            computed: {
                mapStyles () {
                    switch ( this.mapStyle ) {
                        case 'normal':
                            return [];
                        case 'red':
                            return [ {
                                stylers: [ {
                                    hue: '#890000'
                                }, {
                                    visibility: 'simplified'
                                }, {
                                    gamma: 0.5
                                }, {
                                    weight: 0.5
                                } ]
                            }, {
                                elementType: 'labels',
                                stylers: [ {
                                    visibility: 'off'
                                } ]
                            }, {
                                featureType: 'water',
                                stylers: [ {
                                    color: '#890000'
                                } ]
                            } ];
                        default:
                            return [
                                {
                                    "featureType": "water",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#e9e9e9"
                                        },
                                        {
                                            "lightness": 17
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f5f5f5"
                                        },
                                        {
                                            "lightness": 20
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 17
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 29
                                        },
                                        {
                                            "weight": 0.2
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 18
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.local",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 16
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f5f5f5"
                                        },
                                        {
                                            "lightness": 21
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#dedede"
                                        },
                                        {
                                            "lightness": 21
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        },
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 16
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "saturation": 36
                                        },
                                        {
                                            "color": "#333333"
                                        },
                                        {
                                            "lightness": 40
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.icon",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "transit",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f2f2f2"
                                        },
                                        {
                                            "lightness": 19
                                        }
                                    ]
                                },
                                {
                                    "featureType": "administrative",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#fefefe"
                                        },
                                        {
                                            "lightness": 20
                                        }
                                    ]
                                },
                                {
                                    "featureType": "administrative",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#fefefe"
                                        },
                                        {
                                            "lightness": 17
                                        },
                                        {
                                            "weight": 1.2
                                        }
                                    ]
                                }
                            ];
                    }
                }

            },

            methods: {

                init () {


                },

                setPlace(place) {

                    const lat = parseInt(distributor_lat),
                        lng = parseInt(distributor_lng);

                    this.changingCenter = {
                        lat: lat,
                        lng: lng
                    };

                    this.zoom = 12;

                },
                 
                addLocationMarker() {
                      const marker = {
                        lat: parseInt(distributor_lat),
                        lng: parseInt(distributor_lng),
                      };
                      this.markers.push({ position: marker });
                      this.places.push(this.currentPlace);
                      this.center = marker;
                      this.currentPlace = null;
                    
                  },
            },

        } );
    }
};