Vue.component('icon', {
    template: `
    <svg class="c-icon" :class="'c-icon-' + id"><use :xlink:href="'/prototype/dist/sprite/sprite.svg#' + id"></use></svg>
  `,
    props: {
        id: {
            type: String,
            required: true
        }
    }
});