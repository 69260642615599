var steps = function(id) {

    this.el = document.getElementById(id);

    this.tab = {
        el: '.tab',
        list: null
    }

    this.nav = {
        el: '.tab-nav',
        list: null
    }

    this.pag = {
        el: '.tab-pag',
        list: null
    }

    this.count = null;
    this.selected = 0;

    this.lbl = [];

    this.init = function() {
        // Create labels
        this.lbl.submit = this.el.getAttribute('data-lbl-submit');
        this.lbl.next = this.el.getAttribute('data-lbl-next');
        this.lbl.prev = this.el.getAttribute('data-lbl-prev');

        // Create tabs
        this.tab.list = this.createTabList();
        this.count = this.tab.list.length;

        // Create nav
        this.nav.list = this.createNavList();
        this.renderNavList();

        // Create pag
        this.pag.list = this.createPagList();
        this.renderPagList();

        // Set selected
        this.setSelected(this.selected);
    }

    this.createTabList = function() {
        var list = [];

        this.el.r(this.tab.el).forEach(function(el, i) {
            list[i] = el;
        });

        return list;
    }

    this.createNavList = function() {
        var list = [];

        this.tab.list.forEach(function(el, i) {
            var listitem = document.createElement('a');
            listitem.className = 'nav-item',
                listitem.innerHTML = '<span>' + el.getAttribute('data-name') + '</span>';
                listitem.onclick = function() {
                    this.setSelected(i);
                    return false;
                }.bind(this);
            list[i] = listitem;
        }.bind(this));

        return list;
    }

    this.createPagList = function() {
        var list = [];

        list.prev = document.createElement('a');
        list.prev.className = 'c-button c-button-secondary pag-item pag-item-prev',
        list.prev.innerHTML = this.lbl.prev;
        list.prev.onclick = function() {
            this.setSelected(this.selected - 1);
            return false;
        }.bind(this);

        list.next = document.createElement('a');
        list.next.className = 'c-button c-button-primary pag-item pag-item-next',
        list.next.innerHTML = this.lbl.next;
        list.next.onclick = function() {
            this.setSelected(this.selected + 1);
            return false;
        }.bind(this);

        list.submit = document.createElement('button');
        list.submit.className = 'c-button c-button-primary pag-item pag-item-submit';
        list.submit.name = "offerte_submit";
        list.submit.type = "submit";
        list.submit.innerHTML = this.lbl.submit;

        return list;
    }

    this.renderNavList = function() {
        var nav = document.querySelector(this.nav.el);

        this.nav.list.forEach(function(el) {
            nav.appendChild(el);
        });
    }

    this.renderPagList = function() {
        var pag = document.querySelector(this.pag.el);

        pag.appendChild(this.pag.list.prev);
        pag.appendChild(this.pag.list.next);
        pag.appendChild(this.pag.list.submit);
    }

    this.setSelected = function(target) {

        $("#Inputfield_offerte_voornaam").removeClass('c-error');
        $("#Inputfield_offerte_last_name").removeClass('c-error');
        $("#Inputfield_offerte_address").removeClass('c-error');
        $("#Inputfield_offerte_postal_code").removeClass('c-error');
        $("#Inputfield_offerte_city").removeClass('c-error');
        $("#Inputfield_offerte_country").removeClass('c-error');
        $("#Inputfield_offerte_email").removeClass('c-error');
        $("#Inputfield_offerte_phone").removeClass('c-error');
        $("#Inputfield_offerte_privacy").removeClass('c-error-label');
        $(".InputfieldAsmSelect").removeClass('c-error');


        if(target == 2) {

            if(!$("#Inputfield_offerte_voornaam")[0].checkValidity()) {
                $("#Inputfield_offerte_voornaam").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_last_name")[0].checkValidity()) {
                $("#Inputfield_offerte_last_name").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_address")[0].checkValidity()) {
                $("#Inputfield_offerte_address").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_postal_code")[0].checkValidity()) {
                $("#Inputfield_offerte_postal_code").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_city")[0].checkValidity()) {
                $("#Inputfield_offerte_city").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_country")[0].checkValidity()) {
                $("#Inputfield_offerte_country").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_email")[0].checkValidity()) {
                $("#Inputfield_offerte_email").addClass('c-error');
                return;
            }

            if(!$("#Inputfield_offerte_phone")[0].checkValidity()) {
                $("#Inputfield_offerte_phone").addClass('c-error');
                return;
            }

            if(!$("#checkbox-offerte_privacy-1").is(":checked")) {
                $("#Inputfield_offerte_privacy").addClass('c-error-label');
                return;
            }

        }

        var min = 0,
            max = this.count - 1;

        if(target > max || target < min) {
            return;
        }

        if(target == min) {
            this.pag.list.prev.classList.add('hidden');
        } else {
            this.pag.list.prev.classList.remove('hidden');
        }

        if(target == max) {
            this.pag.list.next.classList.add('hidden');
            this.pag.list.submit.classList.remove('hidden');
        } else {
            this.pag.list.next.classList.remove('hidden');
            this.pag.list.submit.classList.add('hidden');
        }

        this.tab.list[this.selected].classList.remove('selected');
        this.nav.list[this.selected].classList.remove('selected');

        this.selected = target;
        this.tab.list[this.selected].classList.add('selected');
        this.nav.list[this.selected].classList.add('selected');
    }
};
