var map = {

    init: function() {

        Vue.use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyDfFjkvWYIkOG43p2_88RQCbPtpMzlYNJ4',
                language: lang,
                region: region,
                libraries: 'places'
            }
        });

        Vue.component('google-map', VueGoogleMaps.Map);
        Vue.component('google-marker', VueGoogleMaps.Marker);

        new Vue({
            el: '#app',
            delimiters: ['<%', '%>'],
            place: null,
            data: {
                search: '',
                selected: 'all',
                place: '',
                infoContent: '',
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,
                infoOptions: {
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                sortedMarkers : [],
                changingCenter: {lat: 50.8579883, lng: 4.3619986},
                zoom: 9,
                scrollwheel: true,
                markersEvent: false,
                markers : distributors
            },

            computed: {

                filter: function() {

                    let self = this;

                    if(self.search === '' && self.selected === 'all') {

                        return distributors;

                    } else if (self.search !== '' && self.selected === 'all') {

                        return self.markers.filter(marker => {
                            return marker.title.toLowerCase().includes(this.search.toLowerCase())
                        });

                    } else {

                        let markers = self.markers.filter(marker => {
                            return marker.title.toLowerCase().includes(self.search.toLowerCase())
                        });

                        return markers.filter(function (marker) {
                            return marker.categories.some(function (categories) {
                                return self.selected.indexOf(categories) !== -1
                            })
                        });
                    }
                },

                mapStyles() {
                    switch (this.mapStyle) {
                        case 'normal':
                            return [];
                        case 'red':
                            return [{
                                stylers: [{
                                    hue: '#890000'
                                }, {
                                    visibility: 'simplified'
                                }, {
                                    gamma: 0.5
                                }, {
                                    weight: 0.5
                                }]
                            }, {
                                elementType: 'labels',
                                stylers: [{
                                    visibility: 'off'
                                }]
                            }, {
                                featureType: 'water',
                                stylers: [{
                                    color: '#890000'
                                }]
                            }];
                        default:
                            return [
                                {
                                    "featureType": "water",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#e9e9e9"
                                        },
                                        {
                                            "lightness": 17
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f5f5f5"
                                        },
                                        {
                                            "lightness": 20
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 17
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 29
                                        },
                                        {
                                            "weight": 0.2
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.arterial",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 18
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.local",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 16
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f5f5f5"
                                        },
                                        {
                                            "lightness": 21
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi.park",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#dedede"
                                        },
                                        {
                                            "lightness": 21
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                        {
                                            "visibility": "on"
                                        },
                                        {
                                            "color": "#ffffff"
                                        },
                                        {
                                            "lightness": 16
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "saturation": 36
                                        },
                                        {
                                            "color": "#333333"
                                        },
                                        {
                                            "lightness": 40
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.icon",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "transit",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "color": "#f2f2f2"
                                        },
                                        {
                                            "lightness": 19
                                        }
                                    ]
                                },
                                {
                                    "featureType": "administrative",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#fefefe"
                                        },
                                        {
                                            "lightness": 20
                                        }
                                    ]
                                },
                                {
                                    "featureType": "administrative",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#fefefe"
                                        },
                                        {
                                            "lightness": 17
                                        },
                                        {
                                            "weight": 1.2
                                        }
                                    ]
                                }
                            ];
                    }
                }

            },

            methods: {

                init() {


                },

                setPlace(place) {

                    const lat = place.geometry.location.lat(),
                        lng = place.geometry.location.lng();

                    this.changingCenter = {
                        lat: lat,
                        lng: lng
                    };

                    this.zoom = 11;
                    this.sortMarkers(lat, lng);

                },

                update(event) {

                    this.sortMarkers(event.lat(), event.lng())
                },

                sortMarkers: function (lat, lng) {

                    // https://stackoverflow.com/questions/4057665/google-maps-api-v3-find-nearest-markers

                    function rad(x) {
                        return x * Math.PI / 180;
                    }

                    var lat = lat;
                    var lng = lng;
                    var R = 6371; // radius of earth in km
                    var distances = [];
                    var closest = -1;

                    var MarkersArr = [];

                    for (var i = 0; i < this.markers.length; i++) {

                        var mlat = this.markers[i].position.lat;
                        var mlng = this.markers[i].position.lng;
                        var dLat = rad(mlat - lat);
                        var dLong = rad(mlng - lng);
                        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                            Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c;
                        distances[i] = d;

                        var arr = {
                            title: this.markers[i].title,
                            lat: this.markers[i].position.lat,
                            lng: this.markers[i].position.lng,
                            d: d
                        };

                        MarkersArr.push(arr);


                        if (closest == -1 || d < distances[closest]) {
                            closest = i;
                        }
                    }

                    function compare(a,b) {
                        if (a.d < b.d)
                            return -1;
                        if (a.d > b.d)
                            return 1;
                        return 0;
                    }

                    MarkersArr.sort(compare);

                    this.sortedMarkers = MarkersArr;

                    //alert(this.markers[closest].title);
                },

                toggleInfoWindow: function (marker, idx) {

                    this.infoWindowPos = marker.position;
                    this.infoContent = marker.infoText;

                    //check if its the same marker that was selected if yes toggle
                    if (this.currentMidx == idx) {
                        this.infoWinOpen = !this.infoWinOpen;
                    }
                    //if different marker set infowindow to open and reset current marker index
                    else {
                        this.infoWinOpen = true;
                        this.currentMidx = idx;
                    }
                }
            },

            beforeMount () {

                this.sortMarkers(50.8579883, 4.3619986)
            }

        });
    }
};